import * as React from 'react'
import { connect } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { withRouter, RouteComponentProps } from 'react-router'
import { ChecklistInstance, ChecklistComponent } from '../../../../types'
import {
  checklistEditFetchChecklist,
  checklistUpdateComponent,
  checklistNextStep,
  printChecklist,
} from '../../../../redux/actions'
import ValidationComponent from '../../widgets/ValidationComponent/ValidationComponent'
import ChecklistSection from './ChecklistSection'
import ChecklistMeta from './ChecklistMeta'
import DeleteChecklistModal from './DeleteChecklistModal'
import 'balloon-css/balloon.min.css'
import { exportCsvChecklist } from '../../../../redux/actions/checklistEdit'
import { Button, PageHeader } from '@mv-submodules/inplant-components-fe'
import MainPageContent from '@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { Tooltip } from 'react-tippy'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

export type UpdateComponentFunction = (
  checklistInstanceId: string,
  componentId: string | undefined,
  value: Array<{ subComponentName: string; value: number }> | string | number | undefined,
  conclude: boolean
) => Promise<any>

export type NextStepFunction = (checklistInstanceId: string) => Promise<any>

export interface StateProps {
  fetching: boolean
  error: Error | null
  checklist: ChecklistInstance | null
  modified: boolean
  validating: boolean
  componentValidations: Array<{ componentId: string; errors: string[] }>
}

export interface DispatchProps {
  fetchChecklist: (id: string) => Promise<any>
  updateComponent: UpdateComponentFunction
  nextStep: NextStepFunction
  printChecklist: (checklistId: string) => Promise<Blob | null>
  exportCsvChecklist: (checklistId: string) => Promise<Blob | null>
}

export interface OwnProps extends RouteComponentProps<any> {
  // t: TranslationFunction
}

export interface OwnState {
  loading: boolean
  isPrinting: boolean
  isDownloadingCsv: boolean
  deleteChecklistModalShow: boolean
}

export interface ProximityData {
  beaconId: string
  minDistance: string
}

export type Props = StateProps & DispatchProps & OwnProps & WithTranslation

const mapStateToProps = (state: any): StateProps => ({
  fetching: state.checklist.edit.fetching,
  error: state.checklist.edit.error,
  checklist: state.checklist.edit.checklist,
  modified: state.checklist.edit.modified,
  validating: state.checklist.edit.validating,
  componentValidations: state.checklist.edit.componentValidations,
})

const mapDispatchToProps = (dispatch: Function): DispatchProps => {
  return {
    fetchChecklist: id => dispatch(checklistEditFetchChecklist(id)),
    nextStep: checklistInstanceId => dispatch(checklistNextStep(checklistInstanceId)),
    updateComponent: (checklistInstanceId, componentId, value, conclude) =>
      dispatch(checklistUpdateComponent(checklistInstanceId, componentId, value, conclude)),
    printChecklist: checklistId => dispatch(printChecklist(checklistId)),
    exportCsvChecklist: checklistId => dispatch(exportCsvChecklist(checklistId)),
  }
}

export class ChecklistEdit extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      isPrinting: false,
      isDownloadingCsv: false,
      deleteChecklistModalShow: false,
    }
    this.concludeChecklist = this.concludeChecklist.bind(this)
    this.nextState = this.nextState.bind(this)
    this.handlePrintChecklist = this.handlePrintChecklist.bind(this)
    this.handleExportCsvChecklist = this.handleExportCsvChecklist.bind(this)
    this.handleDeleteChecklist = this.handleDeleteChecklist.bind(this)
    this.handleDeleteChecklistModalClose = this.handleDeleteChecklistModalClose.bind(this)
  }

  public async componentDidMount() {
    this.setState(prevState => ({ ...prevState, loading: true }))
    try {
      await this.props.fetchChecklist(this.props.match.params.checklistId)
      this.setState(prevState => ({
        ...prevState,
        checklist: this.props.checklist,
        loading: false,
      }))
    } catch (error) {
      this.setState(prevState => ({
        ...prevState,
        checklist: null,
        loading: false,
      }))
    }
  }

  private getProximityComponents() {
    const proximityComponents: ChecklistComponent[] = []
    this.props.checklist!.sections!.forEach(section =>
      section.checks.forEach(check =>
        check.components
          .filter(component => component.type.type === 'proximity')
          .forEach(component => proximityComponents.push(component))
      )
    )
    return proximityComponents
  }

  private updateProximityComponents() {
    const androidInterface = (window as any).Android
    if (typeof androidInterface !== 'undefined' && this.props.checklist!.proximityEnabled) {
      const beacons: ProximityData[] = JSON.parse(androidInterface.getProximityData())
      const proximityComponents = this.getProximityComponents()
      beacons.forEach(beacon => {
        const component = proximityComponents.find(c => c.type.beaconId === beacon.beaconId)
        if (component) {
          component.lastValue = beacon.minDistance
          this.props.updateComponent(this.props.checklist!.id!, component.id, component.lastValue, false)
        }
      })
    }
  }

  private concludeChecklist(event: any) {
    event.persist()
    this.setState(prevState => ({ ...prevState, loading: true }))
    this.updateProximityComponents()
    this.props.updateComponent(this.props.checklist!.id!, undefined, undefined, true).then(() => {
      this.props.history.goBack()
      this.setState(prevState => ({ ...prevState, loading: false }))
    })
  }

  private async nextState() {
    this.setState(prevState => ({ ...prevState, loading: true }))
    try {
      await this.props.nextStep(this.props.checklist!.id!)
      if (this.props.checklist!.currentState().action === 'next') {
        await this.props.fetchChecklist(this.props.match.params.checklistId)
        this.setState(prevState => ({
          ...prevState,
          checklist: this.props.checklist,
          loading: false,
        }))
      } else {
        this.props.history.goBack()
      }
    } catch (error) {
      this.setState(prevState => ({
        ...prevState,
        loading: false,
      }))
    }
  }

  private async handlePrintChecklist() {
    this.setState(prevState => ({ ...prevState, isPrinting: true }))
    try {
      const blob = await this.props.printChecklist(this.props.checklist!.id!)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const checklist = this.props.checklist!
      const date = checklist.concludedDate
        ? mvDate.getDateFromStringWithFormatting(checklist.concludedDate!, 'yyyy-MM-dd HH:mm:ss')
        : mvDate.getDateFromStringWithFormatting(checklist.createdDate!, 'yyyy-MM-dd HH:mm:ss')
      const title = checklist.title
      const asset = checklist.asset ? `[${checklist.asset!.code}] ${checklist.asset!.description}` : null
      link.href = objectURL
      link.download = `${date} - ${title}${asset ? ` - ${asset}` : ''}.pdf`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.setState(prevState => ({ ...prevState, isPrinting: false }))
    } catch (error) {
      this.setState(prevState => ({ ...prevState, error }))
      this.setState(prevState => ({ ...prevState, isPrinting: false }))
    }
  }

  private async handleExportCsvChecklist() {
    this.setState(prevState => ({ ...prevState, isDownloadingCsv: true }))
    try {
      const blob = await this.props.exportCsvChecklist(this.props.checklist!.id!)
      const objectURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const checklist = this.props.checklist!
      const date = checklist.concludedDate
        ? mvDate.getDateFromStringWithFormatting(checklist.concludedDate!, 'yyyy-MM-dd HH:mm:ss')
        : mvDate.format(mvDate.now(), 'yyyy-MM-dd HH:mm:ss')
      const title = checklist.title
      link.href = objectURL
      link.download = `${title}-${date}.csv`
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      link.remove()
      this.setState(prevState => ({ ...prevState, isDownloadingCsv: false }))
    } catch (error) {
      this.setState(prevState => ({ ...prevState, error }))
      this.setState(prevState => ({ ...prevState, isDownloadingCsv: false }))
    }
  }

  private handleDeleteChecklist() {
    this.setState(prevState => ({
      ...prevState,
      deleteChecklistModalShow: true,
    }))
  }

  private async handleDeleteChecklistModalClose() {
    this.setState(prevState => ({
      ...prevState,
      deleteChecklistModalShow: false,
    }))
  }

  private renderChecklistErrorAlert() {
    let message = this.props.t('checklist.edit.errorMessage')
    if (this.props.error?.message.includes('Invalid Exif')) {
      message = this.props.t('checklist.edit.errors.invalidExif')
    }
    return (
      <div className="card border-danger mr-3" style={{ fontSize: '80%' }}>
        <div className="card-body text-danger" style={{ padding: '0.5rem' }}>
          <p className="card-text">{message}</p>
        </div>
      </div>
    )
  }

  public render() {
    const checklistState = this.props.checklist ? this.props.checklist.state : undefined
    const checklistSections =
      this.props.checklist && this.props.checklist.sections
        ? this.props.checklist.sections.filter(
            section =>
              section.checks.some(
                check =>
                  ((this.props.checklist as ChecklistInstance).isConcluded() && check.visible) ||
                  (check.visibilityStep === checklistState && check.visible && check.canBeFilled)
              ) ||
              (!(this.props.checklist as ChecklistInstance).isConcluded() &&
                section.checks.length === 0 &&
                section.customCheckPrototype)
          )
        : []
    return (
      <React.Fragment>
        {!this.props.fetching && this.props.checklist && (
          <DeleteChecklistModal
            show={this.state.deleteChecklistModalShow}
            onClose={this.handleDeleteChecklistModalClose}
            checklistId={this.props.checklist.id!}
          />
        )}
        {!this.props.fetching && this.props.checklist && (
          <PageHeader
            isMobile={window.innerWidth < 768}
            title={this.props.checklist.title}
            backButton={true}
            backButtonOnClick={() => this.props.history.goBack()}
            subtitle={
              (this.props.checklist.asset &&
                `[${this.props.checklist.asset.code}] ${this.props.checklist.asset.description}`) ||
              undefined
            }
            leftButtons={
              (window.innerWidth < 768 && (
                <>
                  {!this.props.checklist.isConcluded() || this.props.checklist.hasNextState() ? (
                    <Button
                      spacing={{ vertical: false }}
                      variant={'success'}
                      ariaLabel={
                        this.props.modified ||
                        (this.props.checklist.invalidChecksCount && this.props.checklist.invalidChecksCount > 0)
                          ? this.props.t('checklist.edit.concludeTooltipText')
                          : ''
                      }
                      dataBalloonPos={
                        this.props.modified ||
                        (this.props.checklist.invalidChecksCount && this.props.checklist.invalidChecksCount > 0)
                          ? 'left'
                          : 'none'
                      }
                      onClick={this.props.checklist.hasNextState() ? this.nextState : this.concludeChecklist}
                      disabled={
                        this.state.loading ||
                        this.props.checklist.isConcluded() ||
                        this.props.validating ||
                        this.props.componentValidations.length > 0 ||
                        this.state.isPrinting
                      }
                      icon={'check'}
                      label={this.props.t(
                        this.props.checklist.hasNextState() ? 'checklist.edit.nextStep' : 'checklist.edit.conclude'
                      )}
                    />
                  ) : null}
                </>
              )) ||
              null
            }
            contentNextToTitle={
              this.props.checklist.generateAlertEmail && (
                <Tooltip title={this.props.t('checklist.edit.checklistEmailInfo')} trigger="mouseenter click">
                  <IconComponent icon={'envelope'} />
                </Tooltip>
              )
            }
            rightButtons={
              (window.innerWidth < 768 && (
                <>
                  {!this.props.checklist.isConcluded() || this.props.checklist.hasNextState() ? (
                    <Button
                      type="button"
                      variant={'secondary-alternate'}
                      spacing={{ vertical: false, horizontal: false }}
                      onClick={this.handleDeleteChecklist}
                      disabled={this.state.isPrinting}
                      icon={'trash'}
                      label={this.props.t('checklist.edit.deleteChecklist')}
                    />
                  ) : null}
                </>
              )) || (
                <>
                  {!this.props.checklist.isConcluded() || this.props.checklist.hasNextState() ? (
                    <Button
                      type="button"
                      variant={'secondary-alternate'}
                      spacing={{ vertical: false, horizontal: true }}
                      onClick={this.handleDeleteChecklist}
                      disabled={this.state.isPrinting}
                      icon={'trash'}
                      label={this.props.t('checklist.edit.deleteChecklist')}
                    />
                  ) : null}
                  {this.props.checklist &&
                    this.props.checklist.attributes &&
                    this.props.checklist.attributes.includes('enable-csv-download') && (
                      <Button
                        type="button"
                        variant={'secondary-alternate'}
                        spacing={{ vertical: false, horizontal: true }}
                        onClick={this.handleExportCsvChecklist}
                        disabled={this.state.isDownloadingCsv}
                        icon={'file-export'}
                        isLoading={this.state.isDownloadingCsv}
                        label={this.props.t('checklist.edit.csv')}
                      />
                    )}
                  <Button
                    type="button"
                    spacing={{ vertical: false }}
                    variant={'secondary-alternate'}
                    onClick={this.handlePrintChecklist}
                    disabled={this.state.isPrinting}
                    icon={'print'}
                    isLoading={this.state.isPrinting}
                    label={this.props.t('checklist.edit.print')}
                  />
                  {!this.props.checklist.isConcluded() || this.props.checklist.hasNextState() ? (
                    <Button
                      spacing={{ vertical: false }}
                      variant={'success'}
                      ariaLabel={
                        this.props.modified ||
                        (this.props.checklist.invalidChecksCount && this.props.checklist.invalidChecksCount > 0)
                          ? this.props.t('checklist.edit.concludeTooltipText')
                          : ''
                      }
                      dataBalloonPos={
                        this.props.modified ||
                        (this.props.checklist.invalidChecksCount && this.props.checklist.invalidChecksCount > 0)
                          ? 'left'
                          : 'none'
                      }
                      onClick={this.props.checklist.hasNextState() ? this.nextState : this.concludeChecklist}
                      disabled={
                        this.state.loading ||
                        this.props.checklist.isConcluded() ||
                        this.props.validating ||
                        this.props.componentValidations.length > 0 ||
                        this.state.isPrinting
                      }
                      icon={'check'}
                      label={this.props.t(
                        this.props.checklist.hasNextState() ? 'checklist.edit.nextStep' : 'checklist.edit.conclude'
                      )}
                    />
                  ) : null}
                </>
              )
            }
          />
        )}
        <MainPageContent loading={this.props.fetching || !this.props.checklist}>
          {this.props.checklist && (
            <>
              <ChecklistMeta checklist={this.props.checklist} />
              {this.props.checklist && this.props.checklist.header && this.props.checklist.header.length > 0 && (
                <div dangerouslySetInnerHTML={{ __html: this.props.checklist.header }} />
              )}
              {checklistSections.map((section, index) => (
                <ChecklistSection key={index} section={section} />
              ))}
              {this.props.checklist && (this.props.checklist.lastValidation || this.props.checklist.canBeValidated) ? (
                <ValidationComponent
                  checklistId={this.props.checklist.id!}
                  lastValidation={this.props.checklist.lastValidation}
                  validationFields={this.props.checklist.validationFields}
                  disabled={this.props.checklist.lastValidation !== null}
                />
              ) : null}
              {!this.props.checklist.isConcluded() || this.props.checklist.hasNextState() ? (
                <div className="d-flex justify-content-end align-items-center">
                  {this.props.error ? this.renderChecklistErrorAlert() : null}
                  <Button
                    spacing={{ horizontal: false, vertical: false }}
                    variant={'success'}
                    size={'lg'}
                    onClick={this.props.checklist.hasNextState() ? this.nextState : this.concludeChecklist}
                    disabled={
                      this.state.loading ||
                      this.props.checklist.isConcluded() ||
                      this.props.validating ||
                      this.props.componentValidations.length > 0 ||
                      this.state.isPrinting
                    }
                    icon={'check'}
                    label={this.props.t(
                      this.props.checklist.hasNextState() ? 'checklist.edit.nextStep' : 'checklist.edit.conclude'
                    )}
                  />
                </div>
              ) : (
                undefined
              )}
            </>
          )}
        </MainPageContent>
      </React.Fragment>
    )
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChecklistEdit)))
