const it = {
  assetManager: {
    navigation: {
      asset: 'Asset',
      'list-active': 'Lista asset',
      'list-archive': 'Archivio asset',
      'list-to-validate': 'Azioni da validare',
      assetsGroups: 'Gruppi asset',
      createAsset: 'Crea nuovo asset in impianto',
      validateAsset: 'Valida asset',
      validateChangeAsset: 'Valida sostituzione asset',
      validateDocuments: 'Valida Documenti asset',
      deactivatedAssets: 'Asset congelati',
      createNewGroup: 'Crea nuovo gruppo',
      updateGroup: 'Modifica gruppo',
      updateAsset: 'Modifica asset',
      disableAsset: 'Disattiva asset',
      freezedAsset: 'Asset congelati',
    },
    fetchErrors: "Errore caricando i dati, riprova o contatta l'amministratore",
    labels: {
      selectAssetsToCopyTasks: "Seleziona l'asset dal quale vuoi copiare le scadenze",
      selectCopyTasks: 'Seleziona scadenze da copiare',
      destinationAsset: 'Asset destinatario',
      selectedTasks: 'Scadenze selezionate',
      tasksToCopy: 'Scadenze da copiare',
    },
    actions: {
      createAsset: 'CREA ASSET',
      createGroup: 'CREA GRUPPO',
      save: 'SALVA',
      validateAsset: 'VALIDA ASSET',
      validateDocuments: 'VALIDA DOCUMENTI',
      updateMachineCode: 'Aggiorna codice macchina',
      validate: 'VALIDA',
      actions: 'AZIONI',
      forward: 'AVANTI',
      confirm: 'CONFERMA',
      update: 'Modifica asset',
      addNote: 'Aggiungi nota',
      addDocument: 'Aggiungi documento',
      disableAsset: 'Disattiva asset',
      scrapAsset: 'ROTTAMA ASSET',
      sellAsset: 'VENDI ASSET',
      changeAsset: 'Sostituisci asset',
      validateChange: 'VALIDA SOSTITUZIONE',
      cancel: 'ANNULLA',
      updateGroup: 'Modifica gruppo',
      disableGroup: 'Disattiva gruppo',
      addAsset: 'AGGIUNGI ASSET',
      removeFromGroup: 'RIMUOVI DAL GRUPPO',
      selectAssetsToCopyTasks: 'Seleziona asset/gruppo da cui copiare',
      copyTasks: 'Copia scadenze',
      summary: 'Riepilogo',
    },
    filters: {
      searchPlaceholderAsset: 'Cerca per i campi della tabella',
      searchByName: 'Cerca per nome',
      allCostCenter: 'Tutti i centri di costo',
      allStates: 'Tutti gli stati',
      allPlants: 'Tutti gli impianti',
      advancedFilters: 'FILTRI AVANZATI',
    },
    forms: {
      activationDate: 'Data attivazione',
      dueAtDate: 'Data di scadenza',
      temporaryPlantCodeAdviseCreation:
        'Inserisci un codice impianto temporaneo che potrà essere sostituito in fase di validazione',
      temporaryPlantCodeAdviseValidation: 'Codice impianto temporaneo, inserisci il valore corretto prima di validarlo',
      endDate: 'Data fine',
      machineCode: 'Codice macchina',
      plantCode: 'Codice impianto',
      assetDescription: 'Descrizione asset',
      plant: 'Impianto',
      site: 'Sito',
      costCenter: 'Centro di costo',
      brand: 'Marca',
      model: 'Modello',
      serialNumber: 'Matricola',
      owner: 'Proprietà',
      ownerType: {
        owned: 'Proprietà',
        rented: 'Noleggio',
        leased: 'Leasing',
        subSupply: 'Sub fornitura',
      },
      notes: 'Note',
      selectGroup: 'Seleziona un gruppo',
      sell: 'Vendi',
      freeze: 'Congela',
      assetsGroups: 'Gruppi asset',
      modules: 'Moduli',
      status: 'Stato',
      noGroups: 'Nessun gruppo selezionato',
      historyActions: 'Storico azioni',
      description: 'Descrizione',
      inHeadquarter: 'In sede',
      notInHeadquarter: 'Non in sede',
      maintenance: 'Manutenzioni',
      other: 'Altro',
      creationDate: 'Data di creazione',
      ce: 'CE',
      ddt_delivery: 'DDT di consegna',
      electric_scheme: 'Schema elettrico',
      selling_scrapping: 'Vendita/Rottamazione',
      drawing: 'Disegno',
      manual: 'Manuale',
      browse: 'Browse',
      documents: 'Documenti',
      groupName: 'Nome gruppo',
      noDocuments: 'Nessun documento caricato',
      scrap: 'Rottama',
      deactivate: 'Sospendi',
      substituteWithNewAsset: 'Sostituisci con nuovo asset',
      substituteWithScrapAsset: 'Sostituisci con asset accantonato',
      deactivatedAsset: 'Asset accantonato',
    },
    actionLogs: {
      creation: 'Asset creato',
      'creation-confirmed': 'Asset validato',
      update: 'Asset modificato',
      note: 'Nota',
      'asset-code-updated': 'Codice macchina aggiornato',
      'deactivation-required': 'Disattivazione richiesta',
      'deactivation-confirmed': 'Disattivazione confermata',
      'deactivation-canceled': 'Disattivazione cancellata',
      'scrapping-required': 'Disattivazione con rottamazione richiesta',
      'scrapping-confirmed': 'Rottamazione confermata',
      'replacing-confirmed': 'Sostituzione confermata',
      'replacing-scrapping-required': 'Sostituzione richiesta',
      'replacing-deactivation-required': 'Sostituzione richiesta',
      'documents-confirmed': 'Documenti validati',
      frozen: 'Asset disattivato e congelato',
      'selling-required': 'Disattivazione con vendita richiesta',
      'freezing-required': 'Disattivazione con congelamento richiesta',
    },
    status: {
      active: 'Attivo',
      'creation-to-validate': 'Nuovo da validare',
      'deactivation-to-validate': 'Disattivazione da validare',
      'scrapping-to-validate': 'Disattivazione da validare',
      'selling-to-validate': 'Disattivazione da validare',
      deactivated: 'Disattivato',
      done: 'Fatto',
      overdue: 'Scaduto',
      postponed: 'Da fare',
      rejected: 'Rifiutato',
      'to-do': 'Da fare',
      'to-validate': 'Da validare',
      canceled: 'Eliminato',
      'in-progress': 'In gestione',
      differentDays: {
        postponed: 'entro {{days}} gg',
        todo: 'entro {{days}} gg',
        overdue: 'da {{days}} gg',
      },
      'all-tasks-done': 'Tutte le scadenze di configurazione sono state completate',
      'replace-with-scrapping-to-validate': 'Sostituzione da validare',
      'replace-with-deactivation-to-validate': 'Sostituzione da validare',
      'replace-to-validate': 'Sostituzione da validare',
      needToValidateAllTasks: 'È necessario concludere le scadenze in sospeso',
      needToValidateAllTasksToSubstitute:
        "Validando la sostituzione di questo asset, andrai a disattivare l'asset {{oldAsset}} e ad attivare l'asset {{newAsset}}",
      'documents-to-validate': 'Documenti da validare',
      'freezing-to-validate': 'Disattivazione da validare',
    },
    modal: {
      title: {
        addNote: "Aggiungi nota all' asset",
        updateMachineCode: 'Aggiorna il codice macchina',
      },
    },
    table: {
      header: {
        status: 'Stato',
        machineCode: 'Cod. macchina',
        plantCode: 'Cod. impianto',
        assetName: 'Nome asset',
        assetData: {
          model: 'Modello',
          serialNumber: 'Matricola',
        },
        costCenter: 'Centro di costo',
        attivationDate: 'Data attivazione',
        timeActive: 'Periodo attivo',
        groups: 'Gruppi',
        groupName: 'Nome gruppo',
        asset: 'Asset',
        rifLegislativo: 'Rif. legislativo',
        id: 'ID',
        ambit: 'Ambito',
        category: 'Categoria',
        subCategory: 'Sottocategoria',
        deadline: 'Scadenza',
        activity: 'Attività',
        brand: 'Marca',
        serialNumber: 'Matricola',
      },
      noData: 'Nessun dato disponibile',
      noItemFound: 'Nessun elemento trovato',
      previous: 'Precedente',
      next: 'Successiva',
      loading: 'Caricamento dati...',
      page: 'Pagina',
      of: 'di',
      rows: 'righe',
    },
    'modules-label': {
      'chrono-frame': 'Scadenziario',
    },
  },
}

export default it
