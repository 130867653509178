import { API } from './index'
import { AnyAction, Dispatch } from 'redux'
import { ChecklistInstance, PartialChecklistInstance } from '../../types/index'
import { logoutUser } from '@mv-submodules/inplant-coreadapter-fe/auth'
import * as Noty from 'noty'
import { CheckPrototype } from '@mv-submodules/inplant-checklist-fe-iblu/ui/components/views/ChecklistEdit/NewCheckModal'

export const checklistEditIsFetching = (): AnyAction => {
  return {
    type: 'CHECKLIST_EDIT_IS_FETCHING',
  }
}

export const checklistEditFetchSuccess = (checklist?: ChecklistInstance | PartialChecklistInstance): AnyAction => {
  return {
    payload: checklist,
    type: 'CHECKLIST_EDIT_FETCH_SUCCESS',
  }
}

export const checklistEditFetchError = (error: Error): AnyAction => {
  return {
    payload: error,
    type: 'CHECKLIST_EDIT_FETCH_ERROR',
  }
}

export const isModified = (modified: boolean): AnyAction => {
  return {
    payload: modified,
    type: 'CHECKLIST_EDIT_IS_MODIFIED',
  }
}

export const isValidating = (validating: boolean): AnyAction => {
  return {
    payload: validating,
    type: 'CHECKLIST_EDIT_IS_VALIDATING',
  }
}

export const addComponentValidation = (validation: { componentId: string; errors: string[] }): AnyAction => {
  return {
    payload: validation,
    type: 'CHECKLIST_EDIT_ADD_COMPONENT_VALIDATION',
  }
}

export const removeComponentValidation = (componentId: string): AnyAction => {
  return {
    payload: componentId,
    type: 'CHECKLIST_EDIT_REMOVE_COMPONENT_VALIDATION',
  }
}

export const isUpdatingComponent = (): AnyAction => {
  return {
    type: 'CHECKLIST_EDIT_IS_UPDATING_COMPONENT',
  }
}

export const checklistEditFetchChecklist = (
  id: string
): ((dispatch: Dispatch<AnyAction>) => Promise<string | null>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(checklistEditIsFetching())
    try {
      const response = await API().request(`/checklist/data/${id}`)
      const checklist = new ChecklistInstance(response)
      dispatch(checklistEditFetchSuccess(checklist))
      return checklist.id
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistEditFetchError(new Error(error)))
      throw error
    }
  }
}

export const checklistUpdateComponent = (
  checklistInstanceId: string,
  componentId: string | undefined,
  value: Array<{ subComponentName: string; value: number | number[] }> | string | number | undefined | null,
  conclude: boolean
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(isModified(true))
    dispatch(isUpdatingComponent())
    try {
      await API().request(`/checklist/update/${checklistInstanceId}`, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          updates: componentId && typeof value !== 'undefined' ? [{ componentId, value }] : [],
          conclude,
        }),
      }).then(() => {
        if (conclude) {
          new Noty({
            text: `
              <div>
                <strong>Operazione completata</strong>
                <br>
                <span>Checklist conclusa con successo</span>
              </div>`,
            type: 'success',
            theme: 'bootstrap-v4',
            layout: 'topRight',
            id: 'fetch-wrapper-noty-container',
            timeout: 5000,
            closeWith: ['click', 'button'],
          }).show()
        }
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistEditFetchError(new Error(error)))
      throw error
    }
  }
}



export const checklistUpdateFile = (
  componentId: string,
  file: File,
  options?: {hideNotification?: boolean}
): ((dispatch: Dispatch<AnyAction>) => Promise<string>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const formData = new FormData()
    // const fileResized = await resizeImage({ file, maxSize: 1500 })
    formData.append('file',file )
    try {
      const response = await API().request(`/component/file/${componentId}`, {
        method: 'POST',
        body: formData,
      }, false, options?.hideNotification)
      return response
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistEditFetchError(new Error(error)))
      throw error
    }
  }
}

export const checklistGetFile = (
  filePath: string,
  options?: { preview: boolean }
): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const qs = options && options.preview ? '?w=640&h=480' : ''
      const blob = await API().request(`/file/${filePath}${qs}`)
      return blob
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistEditFetchError(new Error(error)))
      return undefined
    }
  }
}

export const checklistDeleteFile = (filePath: string): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await API().request(`/file/${filePath}`, {
        method: 'DELETE',
      })
      return response
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistEditFetchError(new Error(error)))
      return undefined
    }
  }
}

export const checklistNextStep = (id: string): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(checklistEditIsFetching())
    try {
      await API().request(`/checklist/next/${id}`, {
        method: 'POST',
      })
      dispatch(checklistEditFetchSuccess())
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      dispatch(checklistEditFetchError(new Error(error)))
    }
  }
}

export const addCheck = (
  sectionId: string,
  checkPrototype: CheckPrototype
): ((dispatch: Dispatch<AnyAction>) => Promise<ChecklistInstance>) => {
  return async dispatch => {
    try {
      const response = await API().request(`/checklist/add-check/${sectionId}`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(checkPrototype),
      })
      return response
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      return null
    }
  }
}

export const deleteCheck = (checkId: string): ((dispatch: Dispatch<AnyAction>) => Promise<void>) => {
  return async dispatch => {
    try {
      const response = await API().request(`/checklist/delete-check/${checkId}`, {
        method: 'DELETE',
      })
      return response
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      return null
    }
  }
}

export const printChecklist = (checklistId: string): ((dispatch: Dispatch<AnyAction>) => Promise<Blob | null>) => {
  return async dispatch => {
    try {
      const blobData = await API().request(`/checklist/print/${checklistId}`)
      return blobData
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      return null
    }
  }
}

export const exportCsvChecklist = (checklistId: string): ((dispatch: Dispatch<AnyAction>) => Promise<Blob | null>) => {
  return async dispatch => {
    try {
      const data = await API().request(`/checklist/csv/${checklistId}`)
      return new Blob([data], { type: 'application/octet-stream' })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
      return null
    }
  }
}

export const deleteChecklist = (checklistId: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    dispatch(checklistEditIsFetching())
    try {
      await API().request(`/checklist/${checklistId}`, {
        method: 'DELETE',
      })
    } catch (error:any) {
      if (error.name === 'FetchError' && error.statusCode === 401) {
        dispatch(logoutUser())
      }
    }
  }
}
